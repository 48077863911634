<template>
  <section class="orders">
    <div class="page-header">
      <h3 class="page-title"> Commande </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Achat</a></li>
          <li class="breadcrumb-item active" aria-current="page">commande</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Commande</h4>
            <div class="row">
              <div class="col-md-4 offset-md-8">
                <b-input-group>
                      <b-form-input placeholder="Recherche"></b-form-input>
                </b-input-group>
              </div>
            </div>
            <div class="row overflow-auto">
              <div class="col-12">
                <b-table :items="items" id="table-list" responsive 
                :per-page="perPage" 
                :current-page="currentPage" 
                :fields="fields" 
                :sort-by.sync="sortBy" 
                :sort-desc.sync="sortDesc">
              <!-- <template v-slot:cell(action)="row">
                  <button  class="btn btn-outline-primary px-4 py-1" @click="invoiceDetails(row)">
                      <a style="color:inherit">
                      <i class="mdi mdi-eye-outline text-primary mr-2"></i>Voir</a>
                  </button>
              </template> -->
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require('axios').default
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
});

export default {
  data: function() {
    return {
      perPage: 10,
      sortBy: 'name',
        currentPage: 1,
        sortDesc: false,
        sortByFormatted: true,
        filterByFormatted: true,
        sortable: true,
        fields: [
          { key: 'ulid', sortable: true },
          { key: 'ulidorder', sortable: true },
          { key: 'Prix', sortable: true },
          { key: 'status', sortable: true },
          // { key: 'action', sortable: true }
        ],
        items: [
        ]
    };
  },
  computed: {
      rows() {
        return this.items.length
      }
    },
  async created () {
    axios.get('https://sites.comolapapaya.com/purchaseorders')
    .then(res=> {
      console.log('res',res)
      this.items= res.data.result
    })
    .catch(err=> {
      console.log('err',err)
    })
  }
}
</script>